import React, { ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ConnectForm } from './contactForm';

const ContactFormFi = (): ReactElement => {
    return (
        <ConnectForm>
            {({ register }: UseFormReturn<any>) => {
                return (
                    <>
                        <div className="field">
                            <label htmlFor="name" className="label">
                                Nimi
                            </label>
                            <div className="control">
                                <input
                                    id="name"
                                    {...register('name')}
                                    name="name"
                                    className="input"
                                    type="text"
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="age" className="label">
                                Ikä
                            </label>
                            <div className="control">
                                <input
                                    id="age"
                                    {...register('age')}
                                    name="age"
                                    className="input"
                                    type="number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="email" className="label">
                                Sähköposti
                            </label>
                            <div className="control">
                                <input
                                    id="email"
                                    {...register('email')}
                                    name="email"
                                    className="input"
                                    type="email"
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <input
                                    type="checkbox"
                                    {...register('experience')}
                                    name="experience"
                                    id="experience"
                                />

                                <label htmlFor="experience" className="checkbox ml-3">
                                    Minulla on kokemusta kamppailulajeista
                                </label>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Viesti</label>
                            <div className="control">
                                <textarea {...register('message')} className="textarea" />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <button type="submit" className="button is-link is-primary">
                                    Lähetä
                                </button>
                            </div>
                        </div>
                    </>
                );
            }}
        </ConnectForm>
    );
};

export default ContactFormFi;
