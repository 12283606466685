import React from 'react';
import ContactForm from '@components/contactForm';
import ContactFormFi from '@components/form.fi';
import ViewContainer from '@components/viewContainer';
import FormContainer from '@components/formContainer';

const Contact = (): React.ReactElement => {
    return (
        <ViewContainer>
            <h1 className="title is-size-4 pl-4">Ota yhteyttä</h1>
            <p className="pl-4 mb-4">
                Jos olet kiinnostunut kuulemaan lisää toiminnastamme, jätä yhteystietosi ja lyhyt
                kuvaus aikaisemmasta kamppailulajikokemuksestasi.
            </p>
            <FormContainer>
                <h2>Päävalmentaja</h2>
                <p>Kimmo Viitaharju, 5. dan</p>
                <p>kimmo.kenshikai@gmail.com</p>
            </FormContainer>
            <br />

            <ContactForm>
                <ContactFormFi />
            </ContactForm>
        </ViewContainer>
    );
};

export default Contact;
